import React from 'react'
import { Link } from 'react-router-dom'

export const Header = ({ isHeaderFooterShown }) => {
    return (
        <>
            <div className="sticky top-0 z-50 bg-[#FFF] py-2 px-0 lg:px-4 xl:px-0 overflow-hidden">
                <nav className="container mx-auto px-4">
                    <div className="flex  items-end md:justify-between justify-center w-full">
                        <div className="flex-shrink-0 mb-4 lg:mb-0 ">
                            <Link to="/">
                                <img src="../../assets/images/logo.png" alt="Logo" className="w-[250px] max-w-xs lg:max-w-none" />
                            </Link>
                        </div>
                        <div className="flex-col lg:flex-row items-center lg:items-start space-y-4 lg:space-y-0 lg:space-x-4 hidden md:flex ">
                            <div id="google_translate_element" className="lg:ml-4"></div>
                        </div>
                    </div>
                </nav>
            </div>
        </>

    )
}

export default Header
