import React from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'

export const AboutUs = () => {

    return (
        <>
            <div className='container mx-auto m-10'>
                <div className="rounded-lg bg-white w-full flex items-center mb-7 lg:mb-12 xl:mb-16">
                    <div className="p-9">
                        <div className='text-base mb-6 lg:py-[3.5px] !leading-none font-medium relative pl-4 after:absolute after:w-[3px] md:after:w-[4px] after:h-[110%] after:top-[-5%] after:bg-[#5AC4D1] after:left-0  after:rounded-md' >
                            <span>About TheGameZone</span>
                        </div>
                        <div className='2xl:pr-36 flex flex-col gap-4 sm:gap-9 lg:gap-10'>
                            <div>
                                <h1 className='text-xl lg:text-2xl mb-6 font-bold capitalize'>
                                    Play Free Games online without downloading
                                </h1>
                                <p className='leading-8 font-medium capitalize'>
                                    TheGameZone is the perfect place for you to instantly play any game, anytime, anywhere. Be it on desktop, mobile, tablet, or Chrome, without downloading. You can enjoy our amazing collection of games.
                                </p>
                            </div>
                            <div>
                                <h2 className='text-xl lg:text-2xl mb-6 font-bold capitalize'>
                                    Benefits of <span className='text-[#5AC4D1]'>playing games</span> online
                                </h2>
                                <p className='leading-8 font-medium capitalize'>
                                    Playing games is not only a leisure activity but has many benefits.
                                </p>
                                <div className='px-10'>
                                    <ul className='list-disc text-base font-medium capitalize leading-8'>
                                        <li>Relaxes your mind</li>
                                        <li>Brings peace</li>
                                        <li>Improves creativity</li>
                                        <li>Improve critical thinking</li>
                                        <li>Improve responsiveness</li>
                                        <li>Releases dopamine or happy hormones to keep you calm</li>
                                    </ul>
                                </div>
                            </div>
                            <div>
                                <h2 className='text-xl lg:text-2xl mb-6 font-bold capitalize'>
                                    Best <span className='text-[#5AC4D1]'>free games</span> online
                                </h2>
                                <p className='leading-8 font-medium capitalize'>
                                    Explore games from our 1000s of single and multiplayer simulator and arcade games. Pick your favorite game from the categories- action, racing, running, sports, cricket, classic, and relaxing games. We’ve got you covered.
                                </p>
                            </div>

                            <div>
                                <h2 className='text-xl lg:text-2xl mb-6 font-bold capitalize'>
                                    Our <span className='text-[#5AC4D1]'>popular</span> games
                                </h2>
                                <p className='leading-8 font-medium capitalize'>
                                    Got confused amongst so many exciting game options? Worry not, try one of our most lovable games:
                                </p>
                                <div className='px-10'>
                                    <ul className='list-disc text-base font-medium capitalize leading-8'>
                                        <Link to="/g/tiny-fishing"><li>Tiny Fishing</li></Link>
                                        <Link to="/g/stickman-parkour"><li>Stickman Parkour</li></Link>
                                        <Link to="/g/om-nom-run"><li>Om Nom Run</li></Link>
                                        <Link to="/g/candy-crush"><li>Candy Crush</li></Link>
                                    </ul>
                                </div>
                            </div>

                            <div>
                                <h2 className='text-xl lg:text-2xl mb-6 font-bold capitalize'>
                                    About <span className='text-[#5AC4D1]'>TheGameZone</span>
                                </h2>
                                <p className='leading-8 font-medium capitalize'>
                                    TheGameZone is a pleasure releasing platform for the best free online fun games. All our games, without download, logins, or any kind of interruptions, are provided with instant play. If you’re bored and looking for some adventure, this is your place. It’s fully secured and easy to use. Play with friends or play alone either way, fun is guaranteed.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutUs;
