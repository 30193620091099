import axios from "axios";

export const baseURL = process.env.REACT_APP_API_BASE_URL || window.location.origin;
// ADMIN INSTANCE
const API = axios.create({ baseURL: `${baseURL}/v1` });
// API.interceptors.request.use((req) => {
//     let token = localStorage.getItem('token');
//     if (token) {
//         req.headers.Authorization = `Bearer ${token}`;
//         localStorage.setItem('appToken', "7f8a73448952efb2811ebd40a72f090a");
//     }
//     return req;
// });

// Gamezone Categories
export const gamezoneCategories = async () => API.get(`/gamezone/getActiveGameCategories`);

export const getGames = async (payload) => API.post(`/gamezone/games`, payload);

export const getGamesByCategories = async (payload) => API.post(`/gamezone/gamesbyCategories`, payload);

export const getAdsSettings = async () => API.get(`/gamezone/userAdsSettings`);

export const updateGamePlayCount = async (id) => API.patch(`/gamezone/updateUserHit?id=${id}`);

export const configSettings = async () => API.get(`/webGlobal/configSettings`);

export const saveFCMToken = async (payload) => API.post(`/gamezone/sendFcmToken`, payload);
