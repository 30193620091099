import { Modal } from '@mui/material';
import React from 'react'
import AdsComponent from './AdsComponent';
import AdCountDown from './AdCountDown';

const AdModal = ({ isOpen, onClose, altAdImage }) => {
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            disableEscapeKeyDown
            className="min-h-screen flex flex-row items-center justify-center backdrop-blur-md"
        >
            <>
                <div className="w-full p-[1px] items-center justify-center -mt-10">
                    <div className="relative rounded-lg group">
                        <p className='text-center text-[10px] text-slate-50 uppercase mb-2'>
                            Advertisement
                        </p>
                        <div className='hidden lg:block xl:block'>
                            <AdsComponent adSize={'768x432'} />
                        </div>
                        <div className='hidden md:block lg:hidden'>
                            <AdsComponent adSize={'500x300'} />
                        </div>
                        <div className='block md:hidden'>
                            <AdsComponent adSize={'336x220'} />
                        </div>
                    </div>
                    <AdCountDown onClose={onClose} isOpen={isOpen} />
                </div>

            </>

        </Modal>
    );
}

export default AdModal
