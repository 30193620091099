import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { gamezone_random_games } from '../../controller/actions';
import { BsArrowsFullscreen } from 'react-icons/bs';
import { NewContext } from '../../Context/NewContext';
import AdsComponent from '../../components/AdsComponent/AdsComponent';
import GameCard from '../../components/GameCard/GameCard';
import { FaPlay } from 'react-icons/fa';
import AdModal from '../../components/AdsComponent/AdModal';
import Data from './../../components/gamesData.json'
import { Helmet } from 'react-helmet';

function GamesPreview({ handleGameClickCount, setIsHeaderFooterShown, gameAdsSettings }) {
    const { isLoading, screenWidth } = useContext(NewContext);
    const navigate = useNavigate();
    // const { state } = useLocation();
    const { name } = useParams();
    const [gamesList, setGamesList] = useState(Data)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [gameId, setGameId] = useState('')
    const [imageBlur, setImageBlur] = useState(true);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [isIOS, setIsIOS] = useState(false);
    const [ShowBanner, setShowBanner] = useState(true);
    const [isFullscreen, setIsFullScreen] = useState(false);
    const [continueGame, setContinueGame] = useState(false);
    const [state, setState] = useState([]);

    // useEffect(() => {
    //     const New_Data = Data.filter((item) => item.slug == name);
    //     setState(New_Data[0]);
    // }, [state])

    useEffect(() => {
        if (name === "*") {
            setState(Data[0]);
        } else {
            const New_Data = Data.filter((item) => item.slug === name);
            setState(New_Data[0] || null);
        }
    }, [name]);




    const isAndroid = navigator.userAgent.toLowerCase().includes('android');
    // const isDesktop = !isIOS && !isAndroid;
    const OpenModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = (event, reason) => {

        if (reason && reason === "backdropClick")
            return;
        setIsModalOpen(false);

        if (isAndroid) {
            setTimeout(() => {
                handleFullScreenToggle();
            }, 200);
        }
        if (isFullscreen) {
            setShowBanner(true);
        }
    };

    // const handleFullScreenToggle = () => {
    //     const iframe = document.getElementById('gamePlayerIframe');
    //     if (iframe.requestFullscreen) {
    //         iframe.requestFullscreen();
    //     } else if (iframe.mozRequestFullScreen) {
    //         iframe.mozRequestFullScreen();
    //     } else if (iframe.webkitRequestFullscreen) {
    //         iframe.webkitRequestFullscreen();
    //     } else if (iframe.msRequestFullscreen) {
    //         iframe.msRequestFullscreen();
    //     }
    // };

    const handleFullScreenToggle = () => {
        const element = document.getElementById('gamePlayerIframe');

        if (element) {
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.mozRequestFullScreen) {
                element.mozRequestFullScreen();
            } else if (element.webkitRequestFullscreen) {
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) {
                element.msRequestFullscreen();
            }
            setIsFullScreen(true);
        }
    };

    useEffect(() => {
        const handleFullScreenChange = () => {
            if (
                !document.fullscreenElement &&
                !document.mozFullScreenElement &&
                !document.webkitFullscreenElement &&
                !document.msFullscreenElement
            ) {
                setShowBanner(true)
                setIsFullScreen(false);
            }
        };

        document.addEventListener('fullscreenchange', handleFullScreenChange);
        document.addEventListener('mozfullscreenchange', handleFullScreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
        document.addEventListener('msfullscreenchange', handleFullScreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullScreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
            document.removeEventListener('msfullscreenchange', handleFullScreenChange);
        };
    }, [isFullscreen]);



    // const getGameListFunc = async () => {
    //     const randomGames = await gamezone_random_games(30);
    //     if (randomGames) {
    //         const { status, data, } = randomGames;
    //         if (status) {
    //             setGamesList(data);
    //         } else {
    //             setGamesList([]);
    //         }
    //     }
    // }
    const handleImageLoad = () => {
        setImageLoaded(true);
        setTimeout(() => setImageBlur(false), 800);
    };

    const handleGame = (link, name, banner) => {
        navigate(`/g/${name.toLowerCase().replace(/ /g, '-')}`, { state: { link, banner, name } });
    }
    // function init(gId) {
    //     // eslint-disable-next-line no-undef
    //     adConfig({
    //         // preloadAdBreaks: "on",
    //         sound: 'off',
    //         onReady: () => {
    //             // eslint-disable-next-line no-undef
    //             adBreak({
    //                 type: "browse", // always called, unblocks the game logic
    //                 name: `game_started_${gId}`,
    //                 afterAd: () => { setPlayDisabled(false) },
    //                 adBreakDone: (placementInfo) => { }
    //             });
    //         },
    //     });
    //     // Don't start the gameplay just yet, keep loading.
    // }

    const playNowHandle = () => {
        OpenModal();
        setShowBanner(false);
    }


    // useEffect(() => {
    //     setShowBanner(true);
    //     if (state?.link) {
    //         const isIosCheck = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    //         setIsIOS(isIosCheck)
    //         if (!isIosCheck) {
    //             setIsHeaderFooterShown(true);
    //         } else {
    //             setIsModalOpen(!isModalOpen);
    //             playNowHandle()
    //         }
    //     } else {
    //         setIsHeaderFooterShown(true);
    //     }
    //     window.scroll({
    //         top: 0,
    //         left: 0,
    //         behavior: "smooth",
    //     });
    // }, [state, gameId])

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, [state])

    return (
        <>
            {
                state?.link &&

                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="theme-color" content="#F4F4F4" />
                    <meta name="apple-mobile-web-app-capable" content="yes" />
                    <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                    <meta name="apple-mobile-web-app-title" content="TheGameZone.fun" />
                    <meta name="HandheldFriendly" content="true" />
                    <meta name="mobile-web-app-capable" content="yes" />
                    <meta http-equiv="x-ua-compatible" content="ie=edge" />
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, minimal-ui, shrink-to-fit=no viewport-fit=cover" />
                    <title>{state?.name} - Play Online for Free! | TheGameZone</title>
                    <meta name="title" content={`${state?.name} - Play Online for Free! | TheGameZone`} />
                    <meta name="description" content={`Play ${state?.name} on the most popular website for free online games! TheGameZone works on your mobile, tablet, or computer. No downloads, no login. Play now!`} />
                    <meta name="keywords" content={`${state?.name} - Play Online for Free! | TheGameZone`} />
                    <meta property="og:title" content={`${state?.name} - Play Online for Free! | TheGameZone`} />
                    <meta property="og:description" content={`Play ${state?.name} on the most popular website for free online games! TheGameZone works on your mobile, tablet, or computer. No downloads, no login. Play now!`} />
                    <meta property="og:url" content="https://thegamezone.fun/" />
                    <meta property="og:site_name" content="TheGameZone" />
                    <meta property="og:image" content="https://thegamezone.fun/assets/images/logo.png" />
                    <meta property="og:image" content="https://thegamezone.fun/assets/images/logo.png" />
                    <meta property="og:type" content="website" />
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={`${state?.name} - Play Online for Free! | TheGameZone`} />
                    <meta name="twitter:description" content={`Play ${state?.name} on the most popular website for free online games! TheGameZone works on your mobile, tablet, or computer. No downloads, no login. Play now!`} />
                    <meta name="twitter:image" content="https://thegamezone.fun/assets/images/logo.png" />
                    <link rel="canonical" href="https://thegamezone.fun/"></link>
                </Helmet>
            }

            {
                isIOS === true ?
                    <>
                        {
                            isModalOpen ? <OpenModal /> : <><iframe src={state?.link} title={state?.name} id='gamePlayerIframe' allow="autoplay" className='w-screen h-screen' /></>
                        }
                    </> :
                    <>
                        <div className='grid grid-cols-6 md:grid-cols-12  gap-5 mt-2 md:mt-7 mb-10'>
                            <div className='col-span-6 md:col-span-2 hidden md:block'>
                                <div className="grid grid-cols-12 ml-2 md:ml-7 gap-3 md:hidden lg:grid">  {/** Side Ads */}
                                    {
                                        <>
                                            <div className="col-span-12">
                                                <div className="rounded-lg bg-white w-full h-fit text-center pt-1">
                                                    <p className='text-[8px] uppercase'>
                                                        Advertisement
                                                    </p>
                                                    <div>
                                                        <AdsComponent adSize={'200x635'} dataAdSlot="9630371299" />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className="grid grid-cols-12 gap-3 lg:hidden">
                                    {
                                        gamesList.slice(0, 5).map((item, i) => {
                                            return (
                                                <div key={i} className="col-span-12 w-28">
                                                    <div className={`bg-[#ffff] rounded-[10px] overflow-hidden shadow-md mt-2`}>
                                                        <div className='cursor-pointer' onClick={() => { handleGame(item?.link, item.name, item.Preview); handleGameClickCount(item._id); setGameId(item._id) }}>
                                                            <div className="overflow-hidden w-full">
                                                                {!imageLoaded && (
                                                                    <div
                                                                        className="bg-gray-300 w-full h-full animate-wave-skeleton"
                                                                    ></div>
                                                                )}
                                                                <img
                                                                    src={item.Preview}
                                                                    alt={item.name}
                                                                    className={`w-full h-full object-cover rounded-[10px] ${!imageLoaded ? 'blur hidden' : 'transition ease-out blur-none duration-700'}`}
                                                                    style={imageBlur ? { filter: 'blur(20px)' } : {}}
                                                                    onLoad={handleImageLoad}
                                                                />
                                                            </div>
                                                            <div className={`p-1 `} >
                                                                <p className="text-[10px] md:text-sm leading-none font-bold text-center truncate">
                                                                    {item.name}
                                                                </p>
                                                            </div>
                                                        </div >
                                                    </div >
                                                </div >
                                            )
                                        })
                                    }
                                </div >
                            </div>
                            <div className="col-span-6 md:col-span-8 m-2 md:m-0">
                                <div className="w-full h-full">
                                    <div className="flex flex-col w-full h-[400px] sm:h-full bg-black rounded-lg">
                                        <iframe src={state?.link} title={state?.name} id='gamePlayerIframe' allow="autoplay" sandbox="allow-scripts allow-same-origin" className='rounded-md w-full !h-[600px] sm:h-full'></iframe>
                                        <div className="w-full bottom-0 bg-white rounded-b-md p-2 px-0 mt-0 z-40">
                                            <div className="flex justify-between items-center mr-3">
                                                <div className='ml-2 flex gap-2'>
                                                    <span> <img src={state?.Preview} className='w-10 h-10 rounded-full' alt='game-banner' /></span>
                                                    <span className='mt-2 font-bold'>{state?.name}</span>
                                                </div>
                                                <BsArrowsFullscreen className='text-black cursor-pointer mr-3 font-bold' onClick={handleFullScreenToggle} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className='col-span-6 md:col-span-2 md:block'>
                                <div className='col-span-6 md:col-span-2 '>
                                    <div className="grid grid-cols-12 gap-3  md:hidden lg:grid">
                                        {
                                            <div className="col-span-12 mr-0 md:mr-7">
                                                <div className="rounded-lg bg-white w-full h-fit text-center pt-1">
                                                    <p className='text-[8px] uppercase'>
                                                        Advertisement
                                                    </p>
                                                    <div className='hidden lg:block xl:block'>
                                                        <AdsComponent adSize={'200x635'} dataAdSlot="9630371299" />
                                                    </div>
                                                    <div className='block md:hidden'>
                                                        <AdsComponent adSize={`${screenWidth}x220`} dataAdSlot="9630371299" />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="grid-cols-12 gap-3 hidden md:grid lg:hidden">
                                        {
                                            gamesList.slice(11, 16).map((item, i) => {
                                                return (
                                                    <div key={i} className="col-span-12 w-28">
                                                        <div className={`bg-[#ffff] rounded-[10px] overflow-hidden shadow-md mt-2`}>
                                                            <div className='cursor-pointer' onClick={() => { handleGame(item?.link, item.name, item.Preview); handleGameClickCount(item._id); setGameId(item._id) }}>
                                                                <div className="overflow-hidden w-full">
                                                                    {!imageLoaded && (
                                                                        <div
                                                                            className="bg-gray-300 w-full h-full animate-wave-skeleton"
                                                                        ></div>
                                                                    )}
                                                                    <img
                                                                        src={item.Preview}
                                                                        alt={item.name}
                                                                        className={`w-full h-full object-cover rounded-[10px] ${!imageLoaded ? 'blur hidden' : 'transition ease-out blur-none duration-700'}`}
                                                                        style={imageBlur ? { filter: 'blur(20px)' } : {}}
                                                                        onLoad={handleImageLoad}
                                                                    />
                                                                </div>
                                                                <div className={`p-1 `} >
                                                                    <p className="text-[10px] md:text-sm leading-none font-bold text-center truncate">
                                                                        {item.name}
                                                                    </p>
                                                                </div>
                                                            </div >
                                                        </div >
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div >
                            </div >
                        </div>

                        {/* <div className="hidden md:block lg:hidden col-span-12 mx-7">
                             Mobile & PC Ads 
                            {gamesList.length > 0 && gameAdsSettings?.gamePreviewSideAd?.isActive && <div className="rounded-lg bg-white w-fit md:w-full h-fit text-center pt-1">
                                <p className='text-[8px] uppercase'>
                                    // Advertisement
                                </p>
                                <div className='block'>
                                    <AdsComponent adSize={'650x220'} dataAdSlot="9630371299" />
                                </div>
                            </div>}
                        </div> */}
                        <amp-ad width={300} height={250} type="doubleclick" data-slot="/21849154601,23199743504/Ad.Plus-300x250"> </amp-ad>

                        {/* bottom card */}
                        < div className='container mx-auto mb-5 m-7 p-3 -mt-12' >



                            {/* <div className="hidden md:block rounded-lg bg-white text-center mt-3 pt-1">
                                <p className="text-[8px] uppercase">Advertisement</p>

                                <div className="hidden lg:block">
                                    <AdsComponent adSize="970x280" dataAdSlot="6934628026" />
                                </div>

                                <div className="md:block lg:hidden">
                                    <AdsComponent adSize="650x280" dataAdSlot="6934628026" />
                                </div>
                            </div> */}


                            <div div className="" >
                                <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-7 gap-4">
                                    {gamesList && gamesList?.slice(5, 26)?.map((_ele, i) =>

                                        <div className='m-0' key={i}>
                                            <GameCard id={_ele._id} slug={_ele.slug} name={_ele.name} banner={_ele.Preview} link={_ele.link} handleGameClickCount={handleGameClickCount} />
                                        </div>

                                    )}

                                    {!isLoading && gamesList.length === 0 && <div className="col-span-12 lg:col-span-12 rounded-lg bg-white w-full h-40 flex justify-center items-center my-4">
                                        <h2 className='text-[28px] font-bold'>
                                            No games found.
                                        </h2>
                                    </div>
                                    }
                                </div>
                            </div>

                            <div className='bg-white p-6 rounded-lg my-5'>
                                <h1 className='text-2xl font-semibold text-gray-800 mb-6'>
                                    How to Play <span className="text-[#5AC4D1]">{state?.name}</span>
                                </h1>
                                <ol className='list-decimal pl-6 space-y-4 text-gray-700'>
                                    <li><strong>Start the Game</strong><br />Click on the “Play” button on the game’s main page to begin.</li>
                                    <li><strong>Understand the Objective</strong><br />Each game has a specific goal. For instance, in puzzle games, the objective might be to solve all puzzles, while in action games, it might be to defeat enemies or complete levels.</li>
                                    <li><strong>Learn the Controls</strong><br />Familiarize yourself with the game controls. Common controls might include:
                                        <ul className='list-disc pl-6 mt-2 space-y-1'>
                                            <li><strong>W, A, S, D</strong> keys for movement</li>
                                            <li><strong>Mouse</strong> for aiming and interacting</li>
                                            <li><strong>Spacebar</strong> for jumping</li>
                                            <li><strong>Enter</strong> or <strong>Return</strong> for confirming actions</li>
                                        </ul>
                                    </li>
                                    <li><strong>Follow On-Screen Instructions</strong><br />Many games provide tutorials or hints within the game. Pay attention to these instructions to learn game-specific mechanics.</li>
                                    <li><strong>Explore the Game</strong><br />Take some time to explore the game’s environment. Try out different strategies and see what works best for you.</li>
                                    <li><strong>Check the Settings</strong><br />Adjust the game settings (e.g., volume, graphics) to your preference for a better experience.</li>
                                    <li><strong>Pause and Save</strong><br />Use the pause menu to save your progress, adjust settings, or exit the game if needed.</li>
                                    <li><strong>Get Help</strong><br />If you encounter difficulties, consult the game’s help section or community forums for tips and solutions.</li>
                                </ol>
                            </div>
                            {/* <div className="rounded-lg bg-white  text-center mt-3 pt-1">
                                <p className='text-[8px] uppercase'>
                                    Advertisement
                                </p>
                                <div className='hidden lg:block'>
                                    <AdsComponent adSize='970x280' dataAdSlot="6934628026" />
                                </div>

                                <div className='hidden md:block lg:hidden'>
                                    <AdsComponent adSize='650x280' dataAdSlot="6934628026" />
                                </div>

                                <div className='block md:hidden'>
                                    <AdsComponent adSize={`${screenWidth}x250`} dataAdSlot="6934628026" />
                                </div>
                            </div> */}
                        </div>

                    </>
            }
            <AdModal isOpen={isModalOpen} onClose={closeModal} />
        </>
    );
};


export default GamesPreview
