import React, { useContext, useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MdOutlineKeyboardBackspace } from 'react-icons/md'
// import { gamezone_all_games, gamezone_latest_games, gamezone_trending_games } from '../../controller/actions';
import GameCard from '../../components/GameCard/GameCard';
import AdsComponent from '../../components/AdsComponent/AdsComponent';
import { NewContext } from '../../Context/NewContext';
import Data from '../../components/gamesData.json'

export const Games = ({ handleGameClickCount, gameAdsSettings }) => {

    const navigate = useNavigate();
    const { category } = useParams();
    const { screenWidth } = useContext(NewContext);

    const [gamesList, setGamesList] = useState(Data)
    const [isLoading, setIsLoading] = useState(true);
    const [pageName, setPageName] = useState("All Games")

    // const getGameListFunc = async () => {
    //     setIsLoading(true)
    //     try {
    //         if (category) {
    //             switch (category.toLowerCase()) {
    //                 case 'trending':
    //                     const trendingGames = await gamezone_trending_games(true);
    //                     if (trendingGames) {
    //                         const { status, data, } = trendingGames;
    //                         if (status) {
    //                             setGamesList(data);
    //                             setPageName('Trending Games');
    //                             setIsLoading(false);

    //                         } else {
    //                             setGamesList([]);
    //                             setPageName('Trending Games');
    //                             setIsLoading(false);
    //                         }
    //                     }
    //                     break;
    //                 case 'latest':
    //                     const latestGame = await gamezone_latest_games(true);
    //                     if (latestGame) {
    //                         const { status, data, } = latestGame;
    //                         if (status) {
    //                             setGamesList(data);
    //                             setPageName('Latest Games');
    //                             setIsLoading(false);
    //                         } else {
    //                             setGamesList([]);
    //                             setPageName('Latest Games');
    //                             setIsLoading(false);
    //                         }
    //                     }
    //                     break;
    //                 default:
    //                     const gameList = await gamezone_all_games();
    //                     if (gameList) {
    //                         const { status, data, } = gameList;
    //                         if (status) {
    //                             setGamesList(data);
    //                             setPageName('All Games');
    //                             setIsLoading(false);
    //                         } else {
    //                             setGamesList([]);
    //                             setPageName('All Games');
    //                             setIsLoading(false);
    //                         }
    //                     }
    //                     break;
    //             }

    //         } else {
    //             const gameList = await gamezone_all_games();
    //             if (gameList) {
    //                 const { status, data, } = gameList;
    //                 if (status) {
    //                     setGamesList(data);
    //                     setPageName('All Games');
    //                     setIsLoading(false);
    //                 } else {
    //                     setGamesList([]);
    //                     setPageName('All Games');
    //                     setIsLoading(false);
    //                 }
    //             }
    //         }

    //     } catch (error) {
    //         console.log(error);
    //         setGamesList([]);
    //     }
    // }

    // useEffect(() => {
    //     setIsLoading(true)
    //     getGameListFunc();
    // }, [category]);

    const adContainerRef = useRef(null);

    useEffect(() => {
        // Load the Google Ad script
        const loadAdScript = () => {
            const script = document.createElement('script');
            script.async = true;
            script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
            document.head.appendChild(script);

            script.onload = () => {
                window.googletag = window.googletag || { cmd: [] };
                window.googletag.cmd.push(function () {
                    window.googletag
                        .defineSlot('/21849154601,23199743504/Ad.Plus-300x250', [300, 250], '300x250-1')
                        .addService(window.googletag.pubads());
                    window.googletag.enableServices();
                    window.googletag.display('300x250-1');
                });
            };
        };

        // Load the ad script only if the adContainerRef is present
        if (adContainerRef.current) {
            loadAdScript();
        }

        // Cleanup function to remove the script when the component unmounts
        return () => {
            const script = document.querySelector('script[src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"]');
            if (script) {
                document.head.removeChild(script);
            }
        };
    }, []);

    return (
        <>
            <div className="container mx-auto mt-5 mb-16">
                <div className="mx-3 lg:mx-0">
                    {/* <div className="my-6 section-title flex items-center">
                        <MdOutlineKeyboardBackspace className='text-3xl mr-6 lg:mr-9 cursor-pointer' onClick={() => navigate('/')} />
                        <h2 className='mr-2 text-base md:text-3xl leading-none font-bold relative pb-2 after:absolute after:w-[45%] after:h-[5px] after:bg-[#5AC4D1] after:bottom-[-5px] after:left-0 after:rounded-md' >{!isLoading && pageName}</h2>
                    </div> */}
                    {/* <div className='mx-auto flex justify-center'>
                        <div className="rounded-lg bg-white w-fit h-fit text-center mt-3 pt-1">
                            <p className='text-[8px] uppercase'>
                                Advertisement
                            </p>
                            <div className='hidden xl:block'>
                                <AdsComponent adSize='1200x280' dataAdSlot="2574950302" />
                            </div>

                            <div className='hidden lg:block xl:hidden'>
                                <AdsComponent adSize='1000x280' dataAdSlot="2574950302" />
                            </div>

                            <div className='hidden md:block lg:hidden'>
                                <AdsComponent adSize='744x280' dataAdSlot="2574950302" />
                            </div>

                            <div className='block md:hidden'>
                                <AdsComponent adSize={`${screenWidth}x250`} dataAdSlot="2574950302" />
                            </div>
                        </div>
                    </div> */}

                    <div className='mx-auto flex justify-center'>
                        <div id="300x250-1" ref={adContainerRef}></div>
                    </div>

                    <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-7 gap-4 mt-3">
                        {gamesList && gamesList?.map((_ele, i) =>
                        // i > 0 && i % 18 === 0 ? (
                        //     <>
                        //         <div className="col-span-2 lg:col-span-6 rounded-lg bg-white w-full h-40 text-center p-5">
                        //             <p className='text-[10px] uppercase'>
                        //                 Advertisement
                        //             </p>
                        //             <div>
                        //                 <AdsComponent dataAdSlot={`${gameAdsSettings?.afterMoreGameAd?.slotIds[Math.floor(Math.random() * gameAdsSettings?.afterMoreGameAd?.slotIds?.length)]}`} />
                        //             </div>
                        //         </div>
                        //         <GameCard key={i} id={_ele._id} name={_ele.name} banner={_ele.Preview} link={_ele.link} handleGameClickCount={handleGameClickCount} />
                        //     </>
                        // ) :
                        (<>
                            <GameCard key={i} id={_ele._id} slug={_ele.slug} name={_ele.name} banner={_ele.Preview} link={_ele.link} handleGameClickCount={handleGameClickCount} />
                        </>
                        )
                        )}
                        {!isLoading && gamesList.length === 0 && <div className="col-span-12 lg:col-span-12 rounded-lg bg-white w-full h-40 flex justify-center items-center my-4">
                            <h2 className='text-[28px] font-bold'>
                                No games found.
                            </h2>
                        </div>
                        }
                    </div>
                    <amp-ad width={300} height={250} type="doubleclick" data-slot="/21849154601,23199743504/Ad.Plus-300x250"> </amp-ad>


                    {/* <div className="rounded-lg bg-white w-full h-fit text-center items-center mt-3 py-5">
                        <p className='text-[8px] uppercase'>
                            Advertisement
                        </p>
                        <div className='hidden xl:block'>
                            <AdsComponent adSize='1200x280' dataAdSlot="6934628026" />
                        </div>

                        <div className='hidden lg:block xl:hidden'>
                            <AdsComponent adSize='1000x280' dataAdSlot="6934628026" />
                        </div>

                        <div className='hidden md:block lg:hidden'>
                            <AdsComponent adSize='744x280' dataAdSlot="6934628026" />
                        </div>

                        <div className='block md:hidden'>
                            <AdsComponent adSize={`${screenWidth}x250`} dataAdSlot="6934628026" />
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default Games
