import React, { createContext, useEffect, useState } from 'react';

export const NewContext = createContext();

const NewContextProvider = ({ children }) => {
    const [token, setToken] = useState(localStorage.getItem('token'));

    const [isAdModalLoading, setIsAdModalLoading] = useState(true);

    const [deleteUpdateYes, setDeleteUpdateYes] = useState("");
    const [deleteUpdateTag, setDeleteUpdateTag] = useState("");
    const [search, setSearch] = useState("");
    const [addEditDeleteTag, setAddEditDeleteTag] = useState("add");
    const [currentHomeModalKey, setCurrentHomeModalKey] = useState("");
    const [currentUserId, setCurrentUserId] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    const [currentThemePage, setCurrentThemePage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [perPage, setPerPage] = useState(15);

    const [childModalCurrentPage, setChildModalCurrentPage] = useState(1);
    const [childModalTotalPage, setchildModalTotalPage] = useState(1);
    const [childModalperPage, setChildModalPerPage] = useState(8);

    const [toggleSidebar, setToggleSidebar] = useState(false);
    const [confirmationModalShow, setConfirmationModalShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalLoading, setIsModalLoading] = useState(false);
    const [isSubModalLoading, setIsSubModalLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const [currentPageData, setCurrentPageData] = useState([]);
    const [currentModalTableData, setCurrentModalTableData] = useState([]);
    const [childModalTableData, setChildModalTableData] = useState([]);
    const [homeScreenData, setHomeScreenData] = useState([]);
    const [homeTableData, setHomeTableData] = useState([]);
    const [checkedValues, setCheckedValues] = useState([]);

    // const [multiTag, setMultiTag] = useState([]);
    const [multiCountry, setMultiCountry] = useState([]);
    const [multiRegion, setMultiRegion] = useState([]);

    const [formData, setFormData] = useState({});
    const [imgObj, setImgObj] = useState({});
    const [deleteUpdateItem, setDeleteUpdateItem] = useState({});
    const [filterDropdown, setFilterDropdown] = useState({});
    const [isLoadMore, setIsLoadMore] = useState("")

    const [openmod, setOpenMod] = useState(false);

    const [childFormData, setChildFormData] = useState({});
    const [childOpen, setChildOpen] = useState(false);
    const [childReportOpen, setReportChildOpen] = useState(false);

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    const handleChildOpen = () => setChildOpen(!childOpen);
    const handleAdModalLoading = () => { setIsAdModalLoading(true) };
    const handleAdModalLoadingClose = () => { setIsAdModalLoading(false) };
    const handleChildClose = () => { setChildOpen(false); setChildFormData({}); setDeleteUpdateYes(""); setDeleteUpdateTag(""); setDeleteUpdateItem({}); setImgObj({}); setCheckedValues([]); setMultiCountry([]); setMultiRegion([]); };

    const handleReportChildOpen = () => setReportChildOpen(true);
    const handleReportChildClose = () => { setReportChildOpen(false); };

    const handleChildChange = (e) => {
        if (e.target.name === "country" || e.target.name === "region") {
            let countryRegionAry = [].slice.call(e.target.selectedOptions).map(item => item.value);
            setChildFormData({ ...childFormData, [e.target.name]: countryRegionAry });
            setDeleteUpdateItem({ ...formData, [e.target.name]: countryRegionAry });
        } else {
            setChildFormData({ ...childFormData, [e.target.name]: e.target.value });
            setDeleteUpdateItem({ ...formData, [e.target.name]: e.target.value });
        }
    }
    const handleChildSwitch = (e) => setChildFormData({ ...childFormData, [e.target.name]: childFormData[e.target.name] ? false : true });

    const handleOpenbtn = () => { setOpenMod(true); };
    const handleClosebtn = () => { setOpenMod(false); };

    // const handleCopyData = (_tag, _string) => { copy(_string); toast.success(`${_tag} Copied.`); };
    const handleFilterChange = (e) => { setFilterDropdown({ ...filterDropdown, [e.target.name]: e.target.value === 'true' ? true : e.target.value === 'false' ? false : "" }) };
    const handleCheckboxChange = (value) => { checkedValues.includes(value) ? setCheckedValues(checkedValues.filter((val) => val !== value)) : setCheckedValues([...checkedValues, value]) };
    const handleSelectAll = (e) => {
        const path = window.location.href.split('/').pop();
        const data = path === 'homescreen' ? homeScreenData : currentPageData;
        e.target.checked ? setCheckedValues([...data.map(_ele => _ele._id)]) : setCheckedValues([])
    };
    const handleDeleteAll = (_deleteUpdateYes) => { setDeleteUpdateYes(_deleteUpdateYes); setDeleteUpdateItem({ id: checkedValues }); setConfirmationModalShow(!confirmationModalShow); };
    // const handleIsLoadMoreSwitch = () => { setIsLoadMore(!isLoadMore); add_setting({ isLoadMore: !isLoadMore }); };
    const handleChange = (e) => {
        if (e.target.name === "country" || e.target.name === "region") {
            let countryRegionAry = [].slice.call(e.target.selectedOptions).map(item => item.value);
            setFormData({ ...formData, [e.target.name]: countryRegionAry });
            setDeleteUpdateItem({ ...formData, [e.target.name]: countryRegionAry });
        } else {
            setFormData({ ...formData, [e.target.name]: e.target.value });
            setDeleteUpdateItem({ ...formData, [e.target.name]: e.target.value });
        }
    };
    // const handleLogout = () => { localStorage.clear(); toast.success("Logout successfully."); }
    const handleSwitch = (e) => setFormData({ ...formData, [e.target.name]: formData[e.target.name] ? false : true });
    const handleSwitchUpdate = (e) => { setDeleteUpdateItem({ id: e.target.id, [e.target.name]: !e.target.checked ? false : true }); setConfirmationModalShow(!confirmationModalShow) };
    const handleOpen = () => setOpen(!open);
    const handleClose = () => { setOpen(false); setDeleteUpdateYes(""); setDeleteUpdateTag(""); setAddEditDeleteTag(`add`); setCurrentHomeModalKey(``); setDeleteUpdateItem({}); setFormData({}); setChildFormData({}); setImgObj({}); setCheckedValues([]); setMultiCountry([]); setMultiRegion([]); };
    const capitalalizeFirstletter = (_str) => _str.charAt(0).toUpperCase() + _str.slice(1);
    const updateStateAryData = (stateAry, fieldAllData, name) => {
        // console.log(stateAry, '--------stateAry');
        // console.log(fieldAllData, '--------fieldAllData');
        // console.log(name, '-------------------------------------------------------------------name');
        let blankAry = [];
        stateAry?.map(_state => {
            let stateDefaultValue;
            if (name === 'country') {
                stateDefaultValue = fieldAllData?.filter(_field_all => _field_all._id === (_state?._id || _state))[0];
            } else {
                stateDefaultValue = fieldAllData?.filter(_field_all => _field_all.name.toLowerCase() === _state?.toLowerCase())[0];
            }
            blankAry.push(stateDefaultValue)
        });
        // console.log(blankAry, '------------blankAry');
        return blankAry?.map(_ele => ({ _id: _ele._id, value: name === 'country' ? _ele.code : _ele.name.toLowerCase(), label: capitalalizeFirstletter(_ele.name), name: name }))
    }
    const updateStateAryChildData = (stateAry, fieldAllData, name) => {
        // console.log(stateAry, '--------stateAry');
        // console.log(fieldAllData, '--------fieldAllData');
        // console.log(name, '-------------------------------------------------------------------name');
        let blankAry = [];
        stateAry?.map(_state => {
            let stateDefaultValue;
            if (name === 'country') {
                stateDefaultValue = fieldAllData?.filter(_field_all => _field_all._id === (_state?._id || _state))[0];
            } else {
                stateDefaultValue = fieldAllData?.filter(_field_all => _field_all.name.toLowerCase() === _state?.toLowerCase())[0];
            }
            blankAry.push(stateDefaultValue)
        });
        // console.log(blankAry, '------------blankAry');
        return blankAry?.map(_ele => ({ _id: _ele._id, value: name === 'country' ? _ele.code : _ele.name.toLowerCase(), label: capitalalizeFirstletter(_ele.name), name: name }))
    }

    const handleSelectMultiCountry = (e) => {
        if (e.length > 0) {
            e.map(_ele => {
                let multiSelectAry = [].slice.call(e).map(item => item._id);
                if (_ele.name === 'country') {
                    setMultiCountry(e);
                    if (childOpen) {
                        setChildFormData({ ...childFormData, [_ele.name]: multiSelectAry })
                    } else {
                        setFormData({ ...formData, [_ele.name]: multiSelectAry })
                    }
                }
            })
        } else {
            setMultiCountry([]);
            if (childOpen) {
                setChildFormData({ ...childFormData, country: [] })
            } else {
                setFormData({ ...formData, country: [] })
            }
        }
    }
    const handleSelectMultiRegion = (e) => {
        if (e.length > 0) {
            e.map(_ele => {
                let multiSelectAry = [].slice.call(e).map(item => item._id);
                if (_ele.name === 'region') {
                    setMultiRegion(e);
                    if (childOpen) {
                        setChildFormData({ ...childFormData, [_ele.name]: multiSelectAry })
                    } else {
                        setFormData({ ...formData, [_ele.name]: multiSelectAry })
                    }
                }
            })
        } else {
            setMultiRegion([]);
            if (childOpen) {
                setChildFormData({ ...childFormData, country: [] });
            } else {
                setFormData({ ...formData, region: [] });
            }
        }
    }
    const handleFileInput = (e) => {
        if (e.target.name === "bigPreviewStickerStore") {
            let bigPreviewAry = [];
            let blobURLS = []
            for (let key of e.target.files) {
                bigPreviewAry.push(key);
                blobURLS.push(URL.createObjectURL(key));
            }
            setImgObj({ ...imgObj, [e.target.name]: blobURLS });
            setFormData({ ...formData, [e.target.name]: bigPreviewAry });
        } else {
            setImgObj({ ...imgObj, [e.target.name]: URL.createObjectURL(e.target.files[0]) });
            setFormData({ ...formData, [e.target.name]: e.target.files[0] });
        }
    }
    const handleSingleSelect = (name, e) => setFormData({ ...formData, [name]: e });
    const handleSingleSelectChild = (name, e) => setChildFormData({ ...childFormData, [name]: e });

    // console.log(formData, '====>>>>formData');
    // console.log(multiCountry, '====>>>>multiCountry');
    // console.log(multiRegion, '====>>>>multiRegion');


    useEffect(() => {
        // Function to update screen width and height when the window is resized
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
            setScreenHeight(window.innerHeight);
        };

        // Attach the event listener
        window.addEventListener('resize', handleResize);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const value = {
        token, setToken,
        screenHeight, screenWidth,
        deleteUpdateYes, setDeleteUpdateYes,
        deleteUpdateTag, setDeleteUpdateTag,
        addEditDeleteTag, setAddEditDeleteTag,
        currentHomeModalKey, setCurrentHomeModalKey,
        search, setSearch,
        currentUserId, setCurrentUserId,

        currentPage, setCurrentPage,
        currentThemePage, setCurrentThemePage,
        totalPage, setTotalPage,
        perPage, setPerPage,

        isAdModalLoading, setIsAdModalLoading, handleAdModalLoading, handleAdModalLoadingClose,

        childModalCurrentPage, setChildModalCurrentPage,
        childModalTotalPage, setchildModalTotalPage,
        childModalperPage, setChildModalPerPage,

        toggleSidebar, setToggleSidebar,
        confirmationModalShow, setConfirmationModalShow,
        isLoading, setIsLoading,
        isModalLoading, setIsModalLoading,
        isSubModalLoading, setIsSubModalLoading,
        open, setOpen,
        openModal, setOpenModal,
        childReportOpen, setReportChildOpen,

        currentPageData, setCurrentPageData,
        currentModalTableData, setCurrentModalTableData,
        childModalTableData, setChildModalTableData,
        homeScreenData, setHomeScreenData,
        homeTableData, setHomeTableData,
        checkedValues, setCheckedValues,

        // multiTag, setMultiTag,
        multiCountry, setMultiCountry,
        multiRegion, setMultiRegion,

        formData, setFormData,
        imgObj, setImgObj,
        deleteUpdateItem, setDeleteUpdateItem,
        filterDropdown, setFilterDropdown,
        isLoadMore, setIsLoadMore,

        openmod, setOpenMod,

        childFormData, setChildFormData,
        childOpen, setChildOpen,

        handleChildOpen,
        handleReportChildOpen,
        handleReportChildClose,
        handleChildClose,
        handleChildChange,
        handleChildSwitch,

        // handleCopyData,
        handleFilterChange,
        handleCheckboxChange,
        handleSelectAll,
        handleDeleteAll,
        // handleIsLoadMoreSwitch,
        handleChange,
        // handleLogout,
        handleSwitch,
        handleSwitchUpdate,
        handleOpen,
        handleClose,



        handleOpenbtn,
        handleClosebtn,
        capitalalizeFirstletter,
        updateStateAryData,
        updateStateAryChildData,
        handleSelectMultiCountry,
        handleSelectMultiRegion,
        handleFileInput,
        handleSingleSelect,
        handleSingleSelectChild
    };
    return (
        <NewContext.Provider value={value}>
            {children}
        </NewContext.Provider>
    )
};

export default NewContextProvider;