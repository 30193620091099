import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './App.css';

import { ContactUs, Games, Home, Category } from './pages';
import { Layout } from './components';
import NewContextProvider from "./Context/NewContext";
import { get_web_global_settings, update_games_clicks } from './controller/actions';
import { useEffect, useState } from 'react';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import TermsOfUse from './pages/TermsOfUse/TermsOfUse';
import GamesPreview from './pages/Games/GamesPreview';
import DMCA from './pages/PrivacyPolicy/DMCA';
// import { requestPermission } from './firebase/firebaseConfig';

function App() {
  const [configData, setConfigData] = useState({});
  const [isConfigLoading, setIsConfigLoading] = useState(false);
  const [isHeaderFooterShown, setIsHeaderFooterShown] = useState(true);

  const handleGameClickCount = async (id) => {
  }

  // const getConfigDataFunc = async () => {
  //   setIsConfigLoading(true)
  //   try {
  //     const configSettingsData = await get_web_global_settings();
  //     if (configSettingsData.status) {
  //       setConfigData(configSettingsData.data)
  //       setIsConfigLoading(false)
  //     } else {
  //       console.error('Config Errror');
  //     }

  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  useEffect(() => {
    setIsConfigLoading(true)
    const isIosCheck = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    // if (!isIosCheck) {
    //   requestPermission()
    // }
    // getConfigDataFunc()
  }, [])

  return (
    <div className='bg-[#f4f4f4] h-screen '>
      <NewContextProvider>
        <Router>
          <Helmet>
            <meta charSet="utf-8" />
            <meta name="theme-color" content="#FFFF" />
            <meta name="apple-mobile-web-app-capable" content="yes" />
            <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
            <meta name="apple-mobile-web-app-title" content="TheGameZone.fun" />
            <meta name="HandheldFriendly" content="true" />
            <meta name="mobile-web-app-capable" content="yes" />
            <meta http-equiv="x-ua-compatible" content="ie=edge" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0, minimal-ui, shrink-to-fit=no viewport-fit=cover" />
            <title>Play Online Games on Thegamezone: Best Free Games Online | TheGameZone </title>
            <meta name="title" content="Play Online Games on TheGameZone : Best Free Games Online | TheGameZone " />
            <meta name="description" content="Play free online games at TheGameZone, the best place to play high-quality browser games. We add new games every day , Play a wide genre of online games instantly with no download or sign-up for free." />
            <meta name="keywords" content="Play Online Games on thegamezone: Best Free Games Online | TheGameZone " />
            <meta property="og:title" content="Play Online Games on thegamezone: Best Free Games Online | TheGameZone " />
            <meta property="og:description" content="Play free online games at TheGameZone, the best place to play high-quality browser games. We add new games every day , Play a wide genre of online games instantly with no download or sign-up for free." />
            <meta property="og:url" content="https://thegamezone.fun/" />
            <meta property="og:site_name" content="TheGameZone.fun" />
            <meta property="og:type" content="website" />
            <meta name="og:image" content="https://thegamezone.fun/assets/images/logo.png" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Play Online Games on thegamezone: Best Free Games Online | TheGameZone " />
            <meta name="twitter:description" content="Play free online games at TheGameZone, the best place to play high-quality browser games. We add new games every day , Play a wide genre of online games instantly with no download or sign-up for free." />
            <meta name="twitter:image" content="https://thegamezone.fun/assets/images/logo.png" />
            <link rel="canonical" href="https://thegamezone.fun/"></link>
            {/* Add other meta tags if needed */}
          </Helmet>
          <Routes>
            <Route element={<Layout configData={configData} isHeaderFooterShown={isHeaderFooterShown} setIsHeaderFooterShown={setIsHeaderFooterShown} />}>
              {/* <Route exact path='/' element={< Home handleGameClickCount={handleGameClickCount} gameAdsSettings={configData.adsControl} />}></Route> */}
              <Route exact path='/:category-games' element={< Games handleGameClickCount={handleGameClickCount} gameAdsSettings={configData.adsControl} />}></Route>  {/* Latest, Trending */}
              {/* <Route exact path='/top-games' element={< Games handleGameClickCount={handleGameClickCount} gameAdsSettings={configData.adsControl} />}></Route> */}
              <Route exact path='/' element={< Games handleGameClickCount={handleGameClickCount} gameAdsSettings={configData.adsControl} />}></Route>
              <Route exact path='/g/:name' element={< GamesPreview handleGameClickCount={handleGameClickCount} gameAdsSettings={configData.adsControl} setIsHeaderFooterShown={setIsHeaderFooterShown} />}></Route>
              {/* <Route exact path='/category/:category' element={< Category handleGameClickCount={handleGameClickCount} gameAdsSettings={configData.adsControl} />}></Route> */}
              <Route exact path='/contact-us' element={< ContactUs contactMail={configData.socialControl?.contactMail} />}></Route>
              <Route exact path='/privacy-policy' element={< PrivacyPolicy />}></Route>
              <Route exact path='/dmca' element={<DMCA />}></Route>
              <Route exact path='/terms-of-use' element={< TermsOfUse />}></Route>
              <Route exact path='/*' element={< Games handleGameClickCount={handleGameClickCount} gameAdsSettings={configData.adsControl} />}></Route>
            </Route>
          </Routes>
        </Router>
      </NewContextProvider>

    </div>
  );
}

export default App;
