import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Tags from '../Tags/Tags'

function GameSliderCard({ id, name, banner, link, isHot, isTop, isNewGame, handleGameClickCount }) {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [imageBlur, setImageBlur] = useState(true); // New state variable for blur effect

    const navigate = useNavigate();

    const handleImageLoad = () => {
        setImageLoaded(true);
        setTimeout(() => setImageBlur(false), 800);
    };

    const handleGame = (link, name) => {
        navigate(`/g/${name.toLowerCase().replace(/ /g, '-')}`, { state: { link, banner, name } });
    }

    return (
        <div className="relative overflow-hidden rounded-lg shadow-lg max-w-full mr-3">
            <Tags isHot={isHot} isTop={isTop} isNewGame={isNewGame} />
            {!imageLoaded && (
                <div
                    className="bg-gray-300 w-full rounded-lg h-[110px] lg:h-[222px] xl:h-[285px] 2xl:h-[344px] animate-wave-skeleton"
                ></div>
            )}
            <img className={`object-cover rounded-lg w-full h-auto ${!imageLoaded ? 'blur hidden' : 'transition ease-out blur-none duration-700'}`} src={banner} alt={name} onLoad={handleImageLoad} style={imageBlur ? { filter: 'blur(20px)' } : {}} />
            <div className="banner_slider_img w-full absolute bottom-0 left-0 px-6 py-4">
                <div className="flex items-center justify-end">
                    {/* <h4 className="lg:text-[35px] font-black leading-normal tracking-tight text-white">{name}</h4> */}
                    {/* <Link to={link} target='_blank' onClick={() => handleGameClickCount(id)}> */}
                    <div onClick={() => { handleGame(link, name); handleGameClickCount(id); }}>
                        <button className="bg-[#fff] px-[22px] py-4 font-semibold text-base leading-none rounded">PLAY NOW</button>
                    </div>
                    {/* </Link> */}
                </div>
            </div>
        </div>
    )
}

export default GameSliderCard
